import axios, { Method } from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { userLogsOutAction } from '../redux/actions/actionCreators'

export default function useAxios() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const baseURL = process.env.REACT_APP_BE_URL

    const axiosRequest = async (url: string, method: Method, data = {}) => {
        try {
            return await axios({ baseURL, url, method, data, withCredentials: true })
        } catch (error: any) {
            return error
        }
    }

    axios.interceptors.response.use(
        response => response,
        async error => {
            try {
                const failedRequest = error.config
                if (failedRequest.url === '/users/login') {
                    return Promise.reject(error)
                } else {
                    if (error.response.status === 401 && failedRequest.url !== '/users/refresh-token') {
                        await axiosRequest('/users/refresh-token', 'POST')
                        const retryRequest = axios(failedRequest)
                        return retryRequest
                    } else if (failedRequest.url === '/users/refresh-token') {
                        dispatch(userLogsOutAction())
                        navigate('/login')
                        return Promise.reject(failedRequest)
                    } else {
                        return Promise.reject(error)
                    }
                }
            } catch (error) {
                console.error(error)
            }
        }
    )

    return axiosRequest

}