import { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import AdminRoute from './components/AdminRoute'
import Loading from './components/Loading'
const Home = lazy(() => import('./pages/Home'))
const Login = lazy(() => import('./pages/Login'))
const NotFound = lazy(() => import('./pages/NotFound'))
const NewGuide = lazy(() => import('./pages/NewGuide'))
const EditGuide = lazy(() => import('./pages/EditGuide'))
const ViewGuide = lazy(() => import('./pages/ViewGuide'))
const AdminOnly = lazy(() => import('./pages/AdminOnly'))

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={ <PrivateRoute><Suspense fallback={<Loading />}><Home /></Suspense></PrivateRoute> } />
        <Route path="/login" element={ <Suspense fallback={<Loading />}><Login /></Suspense> } />
        <Route path="/admin-only" element={ <PrivateRoute><Suspense fallback={<Loading />}><AdminOnly /></Suspense></PrivateRoute> } />
        <Route path="/new" element={ <AdminRoute><Suspense fallback={<Loading />}><NewGuide /></Suspense></AdminRoute> } />
        <Route path="/:guideId" element={ <PrivateRoute><Suspense fallback={<Loading />}><ViewGuide /></Suspense></PrivateRoute> } />
        <Route path="/:guideId/edit" element={ <AdminRoute><Suspense fallback={<Loading />}><EditGuide /></Suspense></AdminRoute> } />
        <Route path="*" element={ <PrivateRoute><Suspense fallback={<Loading />}><NotFound /></Suspense></PrivateRoute> } />
      </Routes>
    </Router>
  )
}

export default App