import ACTIONS from "./actionNames"

export const userLogsInAction = () => ({ type: ACTIONS.USER_LOGS_IN })
export const userLogsOutAction = () => ({ type: ACTIONS.USER_LOGS_OUT })
export const addInfoToCurrentUserAction = (user: IUser) => ({ type: ACTIONS.ADD_INFO_TO_CURRENT_USER, payload: user })

interface IUser {
    _id: string
    firstName: string
    lastName: string
    username: string
    password: string
    role: string
    refreshToken?: string
    generalMessagesUnread?: boolean
    rotaMessagesUnread?: boolean
    salesMessagesUnread?: boolean
    jkMessagesUnread?: boolean
    forcePasswordChange: boolean
    tandcAccepted: boolean
    newUpdateAvailable: boolean
}