import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import useAxios from '../hooks/useAxios'
import { addInfoToCurrentUserAction } from '../redux/actions/actionCreators'

export default function PrivateRoute({ children }: IProps) {

    const axiosRequest = useAxios()
    const dispatch = useDispatch()
    const isLoggedIn = useSelector((state: IReduxStore) => state.user.isLoggedIn)
    const currentUser = useSelector((state: IReduxStore) => state.user.currentUser)

    const fetchMyInfo = async () => {
        try {
            const response = await axiosRequest('/users/me', 'GET')
            dispatch(addInfoToCurrentUserAction(response.data))
        } catch (error) {
            console.log(error)
        }
    }

    if (isLoggedIn && !currentUser) fetchMyInfo()

    return isLoggedIn ? children : <Navigate to="/login" />
}

interface IProps {
    children: JSX.Element
}

interface IReduxStore {
    user: {
        isLoggedIn: boolean
        currentUser: IUser | null
    }
}

interface IUser {
    _id: string
    firstName: string
    lastName: string
    username: string
    password: string
    role: string
    refreshToken?: string
    generalMessagesUnread?: boolean
    rotaMessagesUnread?: boolean
    salesMessagesUnread?: boolean
    jkMessagesUnread?: boolean
    forcePasswordChange: boolean
    tandcAccepted: boolean
    newUpdateAvailable: boolean
}