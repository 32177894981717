import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { persistor, storeConfig } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import App from './App'
import './index.css'

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 60 * 10 } }
})

const root = createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <Provider store={storeConfig}>
      <PersistGate persistor={persistor}>
        <HelmetProvider>  
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools />
          </QueryClientProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)